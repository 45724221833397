import { Button, FormControlLabel, Stack, Switch } from '@mui/material';
import { useRef, useState } from 'react';
import zipcelx from 'zipcelx';
import { BryntumGantt, BryntumGanttProps } from '@bryntum/gantt-react';
import { Fullscreen, Model } from '@bryntum/gantt';
import { ganttConfig } from 'src/appConfig';
// import { Store } from '@bryntum/gantt';
import { Deck } from 'src/components/ui-components';
import { IconButton, Typography } from 'src/components/mui-components';
import { ZoomIn, ZoomInMap, ZoomOut } from '@mui/icons-material';
import { projectConfig } from './projectConfig';

const thisConfig: BryntumGanttProps = {
  ...ganttConfig,
  project: projectConfig(),
  features: {
    pdfExport: {
      // Need to have a server running that serves the export server
      // https://bryntum.com/products/gantt/docs/api/Gantt/feature/export/PdfExport
      exportServer: 'http://localhost:8080',
      // Required for font-awesome icons to display correctly
      translateURLsToAbsolute: 'http://localhost:3000',
      clientURL: 'http://localhost:3000',
      keepPathName: false,

      // headerTpl,
      // footerTpl,
    },
    excelExporter: {
      zipcelx,
      // dateFormat: 'YYYY-MM-DD HH:mm',
    },
    // timeRanges: {
    //   showCurrentTimeLine: true,
    // },
  },
  // tbar: [
  //   'Gantt view',
  //   '->',
  //   {
  //     ref: 'undoredoTool',
  //     type: 'undoredo',
  //     text: true,
  //     items: {
  //       transactionsCombo: null,
  //     },
  //   },
  // ],
};

export const Project = () => {
  // const customType = new Store({ id: 'customType' });
  const gantt = useRef<BryntumGantt>(null);
  const ganttInstance = gantt.current?.instance;

  const pdf = {
    // Need to have a server running that serves the export server
    // https://bryntum.com/products/gantt/docs/api/Gantt/feature/export/PdfExport
    exportServer: 'http://localhost:8080',
    // Required for font-awesome icons to display correctly
    translateURLsToAbsolute: 'http://localhost:3000',
    clientURL: 'http://localhost:3000',
    keepPathName: false,

    // headerTpl,
    // footerTpl,
  };

  const [criticalPathEnabled, setCriticalPathEnabled] = useState(true);

  const handleChange = () => {
    setCriticalPathEnabled((prev) => !prev);
    if (gantt.current) {
      gantt.current.instance.features.criticalPaths.disabled = !criticalPathEnabled;
    }
  };

  const toggleFullscreen = () => {
    if (Fullscreen.enabled) {
      if (!Fullscreen.isFullscreen) {
        Fullscreen.request(document.body);
      } else {
        Fullscreen.exit();
      }
    }
  };

  const addTask = async () => {
    if (ganttInstance === undefined) return;

    const added = ganttInstance.taskStore.rootNode.appendChild({
      name: 'New task',
      duration: 1,
    });

    // wait for immediate commit to calculate new task fields
    await ganttInstance.project.commitAsync();

    // scroll to the added task if it exists
    if (added) {
      await ganttInstance.scrollRowIntoView(added as Model);

      ganttInstance.features.cellEdit.startEditing({
        record: added,
        field: 'name',
      });
    }
  };

  return (
    <Deck noPaddingBottom>
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          sx={{ px: 2 }}
        >
          <Typography component="h1" variant="h2" fontWeight={600}>
            Project
          </Typography>
          <Stack direction="row" justifyContent="end" gap={2} sx={{ px: 2 }}>
            <Stack direction="row" gap={1}>
              <FormControlLabel
                control={<Switch value={criticalPathEnabled} onChange={handleChange} />}
                label="Critical path"
                labelPlacement="start"
              />
              <IconButton title="Zoom to fit" onClick={() => ganttInstance?.zoomToFit()}>
                <ZoomInMap />
              </IconButton>
              <IconButton title="Zoom in" onClick={() => ganttInstance?.zoomIn()}>
                <ZoomIn />
              </IconButton>
              <IconButton title="Zoom out" onClick={() => ganttInstance?.zoomOut()}>
                <ZoomOut />
              </IconButton>
            </Stack>
            <Button variant="outlined" size="small" onClick={() => addTask()}>
              New task
            </Button>
            <Button variant="outlined" size="small" onClick={() => ganttInstance?.expandAll()}>
              Expand all
            </Button>
            <Button variant="outlined" size="small" onClick={() => ganttInstance?.collapseAll()}>
              Collapse all
            </Button>
            <Button variant="outlined" size="small" onClick={toggleFullscreen}>
              Fullscreen
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => ganttInstance?.features.pdfExport.showExportDialog()}
            >
              Export to PDF
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                ganttInstance?.features.excelExporter.export({
                  filename: 'export',
                })
              }
            >
              Export to excel
            </Button>
          </Stack>
        </Stack>

        <BryntumGantt
          // columns={[
          //   {
          //     renderer: ({ record }: { record: any }) => <h1>{record.name}</h1>,
          //   },
          // ]}
          ref={gantt}
          pdfExportFeature={pdf}
          excelExporterFeature={{
            zipcelx,
            // dateFormat: 'YYYY-MM-DD HH:mm',
          }}
          {...thisConfig}
        />
      </Stack>
    </Deck>
  );
};
