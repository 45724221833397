import { Routes, Route } from 'react-router-dom';
import ScreenHeader from 'src/components/layout/ScreenHeader';
import { ResourcePlanner } from 'src/screens/ResourcePlanner';
import { SearchProjects } from 'src/screens/SearchProjects';
import { designSevenPagePath } from 'src/designSevenPagePath';
import { Project } from 'src/screens/Project';
import PageRoute from '../PageRoute';

export const ProjectsRouter = () => (
  <Routes>
    <Route
      path={designSevenPagePath.projects.project}
      element={
        <PageRoute
          pageTitle="Project"
          pageId="64706b77-b9c9-4e53-a4df-c17c946dec83"
          pageIdentifier="Project"
        >
          <Project />
        </PageRoute>
      }
    />
    <Route
      path={designSevenPagePath.projects.resourcePlanner}
      element={
        <>
          <ScreenHeader />
          <PageRoute
            pageTitle="ResourcePlanner"
            pageId="c210b1c5-f3df-44d8-b17c-43c10a504c0d"
            pageIdentifier="NewResourcePlanner"
          >
            <ResourcePlanner />
          </PageRoute>
        </>
      }
    />

    <Route
      path={designSevenPagePath.projects.searchProjects}
      element={
        <>
          <ScreenHeader />
          <PageRoute
            pageTitle="SearchProjects"
            pageId="02AD0552-519E-4571-A997-B2641959BACC"
            pageIdentifier="DesignSevenSearchProject"
          >
            <SearchProjects />
          </PageRoute>
        </>
      }
    />
  </Routes>
);
