/**
 * Application configuration
 */
import { BryntumGanttProps } from '@bryntum/gantt-react';

const ganttConfig: BryntumGanttProps = {
  // project: {
  //   autoLoad: true,
  //   transport: {
  //     load: {
  //       url: '/data.json',
  //     },
  //   },
  //   // This config enables response validation and dumping of found errors to the browser console.
  //   // It's meant to be used as a development stage helper only so please set it to false for production systems.
  //   validateResponse: true,
  // },
  columns: [
    {
      type: 'wbs',
      field: 'id',
      width: 70,
      renderer: ({ record }) => record.id.toString().split('').join('.'),
      align: 'right',
    },
    { type: 'name', field: 'name', width: 250 },
    { type: 'date', field: 'startDate', text: 'Start' },
    { type: 'date', field: 'endDate', text: 'End' },
    { type: 'resourceassignment', width: 120, showAvatars: true },
    { type: 'percentdone', showCircle: true, width: 70 },
    { type: 'addnew' },
    // { type: 'name', field: 'customType', text: 'Type' },
  ],
  viewPreset: 'weekAndDayLetter',
  barMargin: 8,
};

export { ganttConfig };
