/**
 * Application configuration
 */
// import { ProjectModel, ProjectModelConfig } from '@bryntum/gantt';
// import { RefObject } from 'react';
// import { generateUrl } from 'src/utils/fetcher/fetcher';

// export const projectConfig = (id: string) => ({
export const projectConfig = () => ({
  autoLoad: true,
  transport: {
    load: {
      url: '/data.json',
    },
  },
  // autoSync: true,
  // loadUrl: generateUrl({ path: `/api/v2/projects/gantt/${id}`, key: 'gantt' }),
  // syncUrl: generateUrl({ path: `/api/v2/projects/gantt/${id}`, key: 'gantt' }),
  // transport: {
  //   load: {
  //     url: '/public/data.json',
  //   },
  // },
  // This config enables response validation and dumping of found errors to the browser console.
  // It's meant to be used as a development stage helper only so please set it to false for production systems.
  validateResponse: true,
});
